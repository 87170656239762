body{
    background-color: #FDF9EC;
    font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#root, body, html {
    height: 100%;
    width: 100%;
}

a {
    color: #E24302;
    text-decoration: none;
}

label{
    font-weight: bold;
}

a:hover {
    color: #a12b00;
}

.form-check-input:checked {
    background-color: #E24302;
    border-color: #E24302;
}

.step-success{
    border-bottom: #E24302 3px solid;
}

.step-missing{
    border-bottom: #D5E2EA 3px solid;
}



.bg-secondary{
    background-color: rgb(44, 62, 80) !important;
}

.btn{
    border-radius: 28px;
    padding-left: 5rem;
    padding-right: 5rem;
}

.btn-secondary{
    background-color: transparent;
    color: #E24302;
    border-color: #E24302;
}

.btn-secondary:hover{
    background-color: #E24302;
    color: #FDF9EC;
    border-color: #E24302;
}

.input-group-text{
    background-color: white;
    border-right-color: transparent;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: transparent;
}

.form-control{
    border-color: transparent;
}

.btn-primary{
    background-color: #E24302 !important;
    border-color: #E24302 !important;
}

.main-banner{
    background-color: rgb(26, 188, 156);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 96px;
    padding-top: 100px;
    text-align: center;
}

.main-logo{
    width: 350px;
    max-width: 50%;
}

.navbar-brand{
    font-size: 1.5rem;
}

h2{
    margin-top: 2rem;
    font-size:3rem;
    color: rgb(44, 62, 80);

}

p{
    font-size: 1.5rem;
}

.color-gray{
    color: #818181;
}

.color-primary{
    color: #E24302;
}

.dashed-border{
    border: 1px gray dashed;
    border-radius: 10px;
}

.nav-link{
    font-size: 1.2rem;
}

.navbar-light .navbar-nav .nav-link {
    color: #FCCD67;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #E24302
}

#mainNav {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
}

.fondo-corporativo{
    background-color: #FDF9EC;
}

.horizontal-slider {
    width: 100%;
    max-width: 500px;
    height: 50px;
    margin: auto;
}
.example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 10px solid #E24302;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;

    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.bg-important{
    background-color: #D5E2EA;
}

.bg-cards{
    background-color: #FDE3AB;
}



.example-thumb.active {
    background-color: grey;
}
.example-track {
    position: relative;
    background: #ddd;
}
.example-track.example-track-0 {
    background: #E24302;
}
.horizontal-slider .example-track {
    top: 20px;
    height: 4px;
}
.horizontal-slider .example-thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
}
