.serviceTitle{
    font-size: 13px;
    color: #818181;

}

.serviceLabel{
    font-size: 15px;
    font-weight: bold;
    color: black;
}

.serviceBorder{
    border: 2px solid #D5E2EA;
    padding: 10px;
    border-radius: 10px;
}