.main-title{
    font-size: 4rem;
    line-height: 3.5rem;
}


.background-image{
    background-image: url("../images/background_intro.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
}



h1{
    font-size: 4rem;
    line-height: 6rem;
}


